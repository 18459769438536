/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import {
  useMaterialUIController,
  setOpenConfigurator,
  setSidenavColor,
  setDarkMode,
} from "/context";

// next
import dynamic from "next/dynamic";
import Link from "next/link";

// MUI
import { styled } from "@mui/material/styles";


// MUI
const Divider = dynamic(() => import("@mui/material/Divider"), { loading: () => null });
const Drawer = dynamic(() => import("@mui/material/Drawer"), { loading: () => null });
const IconButton = dynamic(() => import('@mui/material/IconButton'), { loading: () => null });
const { Link: MuiLink } = dynamic(() => import("@mui/material/Link"), { loading: () => null });
const Switch = dynamic(() => import('@mui/material/Switch'), { loading: () => null });

// MUI Icons
const CloseRounded = dynamic(() => import('@mui/icons-material/CloseRounded'), { loading: () => null });
const Facebook = dynamic(() => import('@mui/icons-material/Facebook'), { loading: () => null });
const Twitter = dynamic(() => import('@mui/icons-material/Twitter'), { loading: () => null });
const PhoneEnabledRounded = dynamic(() => import('@mui/icons-material/PhoneEnabledRounded'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

const ConfiguratorRoot = styled(Drawer)(({ theme, ownerState }) => {
  // drawer styles when openConfigurator={true}
  const drawerOpenStyles = () => ({
    width: 360,
    left: "initial",
    right: 0,
    transition: theme.transitions.create("right", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  });

  // drawer styles when openConfigurator={false}
  const drawerCloseStyles = () => ({
    left: "initial",
    right: theme.functions.pxToRem(-350),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  });

  return {
    "& .MuiDrawer-paper": {
      height: "100vh",
      margin: 0,
      padding: `0 ${theme.functions.pxToRem(10)}`,
      borderRadius: 0,
      boxShadow: theme.boxShadows.lg,
      overflowY: "auto",
      ...(ownerState.openConfigurator ? drawerOpenStyles() : drawerCloseStyles()),
    },
  };
});

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, sidenavColor, darkMode } = controller;

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5" component="h2">UI Configurator</MDTypography>
          <MDTypography variant="body2" color="text" fontWeight="regular">
            Choose your preferences.
          </MDTypography>
        </MDBox>

        <CloseRounded
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(dispatch, false)}
        />
      </MDBox>
      <Divider />
      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox>
          <MDTypography variant="h6" component="h3">Accent Color</MDTypography>

          <MDBox mb={0.5}>
            {[
              "primary",
              "dark",
              "info",
              "success",
              "warning",
              "error",
            ].map((color) => (
              <IconButton
                aria-label={`set accent color to ${color}`}
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main
                    }`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;
                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }
                    return borderColorValue;
                  },
                  transition: transitions.create("border-color", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({
                    functions: { linearGradient },
                    palette: { gradients },
                  }) =>
                    linearGradient(
                      gradients[color].main,
                      gradients[color].state,
                    ),

                  "&:not(:last-child)": {
                    mr: 1,
                  },

                  "&:hover, &:focus, &:active": {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography component="label" variant="h6" htmlFor="light-dark-switch">Light / Dark</MDTypography>
          <Switch id="light-dark-switch" checked={darkMode} onChange={() => setDarkMode(dispatch, !darkMode)} />
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDBox mb={2}>
            <MDButton
              fullWidth
              href="tel:1-631-424-5544"
              startIcon={<PhoneEnabledRounded color="success" />}
              variant="gradient"
              color="dark"
              fontWeight={300}
            >
              {controller.mobileView === false && "(631) 424-5544"}
              {controller.mobileView === true && "Call"}
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={3} mb={2}>
          <MDBox mb={2}>
            <Link href="/contact#message" scroll={false}>
              <MDButton
                onClick={() => setOpenConfigurator(dispatch, false)}
                color="dark"
                variant="gradient"
                fullWidth
              >
                Send us a message
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
        <MDBox mt={2} textAlign="center">
          <MDBox mb={0.5}>
            <MDTypography variant="h6" component="h2">Thank you for sharing!</MDTypography>
          </MDBox>

          <MDBox display="flex" justifyContent="center">
            <MDBox mr={1.5}>
              <MDButton
                component={MuiLink}
                href="//twitter.com/intent/tweet?text=Check%20out%20Autumn%20Leaf&url=https%3A%2F%2Fautumnleaflandscape.com"
                target="_blank"
                rel="noreferrer"
                color="dark"
              >
                <Twitter />
                &nbsp; Tweet
              </MDButton>
            </MDBox>
            <MDButton
              component={MuiLink}
              href="https://www.facebook.com/sharer/sharer.php?u=https://autumnleaflandscape.com"
              target="_blank"
              rel="noreferrer"
              color="dark"
            >
              <Facebook />
              &nbsp; Share
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
